.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  scroll-behavior: smooth;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.gradient-border {
  background: url('/public/images/gradient-border.png');
  background-size: 100% 100%;
  /* border: 2px solid transparent;
  background-clip: padding-box;
  border-image-source: radial-gradient(100% 70% at 40% 40%, transparent, #A09AFF, rgba(160, 154, 255, 0));
  border-image-slice: 1 !important;
  border-radius: 10px;  */
}


.recap {
  background: url('/public/images/recap-bg.png');
  background-size: 100% 100%;
  max-width: 949px;
  margin: 0 auto;
  padding-top: 30px;
}
.AccordionTrigger {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
width: 100%;
}
/* styles.css */
.AccordionContent {
  overflow: hidden;
}
.AccordionContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
  animation-fill-mode: forwards;
  border: rgba(0,211,239,.1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms ease-in-out;
  animation-fill-mode: backwards
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 14px;
  }
}

@media (min-width: 1500px) {
  .AccordionContent[data-state='open'] {
    animation: slideDown 300ms ease-out;
    animation-fill-mode: forwards;
    height: 90px;
  }
  .AccordionContent[data-state='closed'] {
    animation: slideUp 300ms ease-in;
    animation-fill-mode: backwards;
    height: 0;
  }
  
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: 80px;
    }
  }
  
  @keyframes slideUp {
    from {
      height: 80px;
    }
    to {
      height: 0
    }
  }
}



@font-face {
  font-family: 'FreeSans';
  src: local('FreeSans'), url('/public/fonts/FreeSans.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FreeSansBold';
  src: local('FreeSansBold'), url('/public/fonts/FreeSansBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}


.font-bold {
  font-family: 'FreeSansBold' !important;
}
* {
  font-family: 'FreeSans', sans-serif !important;
}




.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #5F55F9;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.max-w-3 {
  display: absolute;
  display: block;
}

@media (max-width: 2000px) {
  .subfooter-ui {
    position: absolute;
    right: 0;
  }
  .subfooter-xl-ui {
    max-width: 50%;
    display: none;
  }
}

@media (min-width: 2000px) {
  .subfooter-ui {
    display: none;
  }
  .subfooter-xl-ui {
    max-width: 50%;
  }

  .custom-w{
    display: none; /* or any other appropriate display property */
  }


}

@media (min-width: 2800px) {

}