@tailwind base;
@tailwind components;
@tailwind utilities;

 /* width */
 ::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #080A24;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5F55F9;
    border-radius: 6px;
  }
  

  .drop-bg {
    background-image: url('/public/images/drop-bg.png');
    background-size: 100% 100%;
    padding: .5em;
  }

  /* styles.css */
.AccordionChevron {
    transition: transform 300ms;
  }
  .AccordionTrigger[data-state='open'] > .drop-bg {
    transform: rotate(180deg);
  }
  

